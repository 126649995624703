import React from "react";
import bannerStyles from "./Banner.styles";
import Marquee from "react-fast-marquee";
import { ReactComponent as BackgroundImage } from "../../assets/images/banner-bg.svg";
import { renderToStaticMarkup } from "react-dom/server";
import Colors from "../../utils/colorConstants";
require("@lottiefiles/lottie-player");

const BG = encodeURIComponent(renderToStaticMarkup(<BackgroundImage />));

const Banner = () => {
  const classes = bannerStyles();

  return (
    <div
      className={classes.banner}
      style={{
        backgroundImage: `url("data:image/svg+xml,${BG}")`,
      }}
    >
      <div className={`${classes.bannerContent} container`}>
        <div className={`${classes.bannerContentLeft} slide-in-left`}>
          <div className={classes.bannerTitle}>{`Hi there, my name is`}</div>

          <svg
            viewBox="0 0 742 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.myName}
          >
            <path
              d="M75.576 5.79994L46.488 72.9999H31.128L2.13599 5.79994H18.936L39.384 53.7999L60.12 5.79994H75.576Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M97.119 20.5839C105.119 20.5839 111.263 22.5039 115.551 26.3439C119.839 30.1199 121.983 35.8479 121.983 43.5279V72.9999H107.967V66.5679C105.151 71.3679 99.903 73.7679 92.223 73.7679C88.255 73.7679 84.799 73.0959 81.855 71.7519C78.975 70.4079 76.767 68.5519 75.231 66.1839C73.695 63.8159 72.927 61.1279 72.927 58.1199C72.927 53.3199 74.719 49.5439 78.303 46.7919C81.951 44.0399 87.551 42.6639 95.103 42.6639H107.007C107.007 39.3999 106.015 36.9039 104.031 35.1759C102.047 33.3839 99.071 32.4879 95.103 32.4879C92.351 32.4879 89.631 32.9359 86.943 33.8319C84.319 34.6639 82.079 35.8159 80.223 37.2879L74.847 26.8239C77.663 24.8399 81.023 23.3039 84.927 22.2159C88.895 21.1279 92.959 20.5839 97.119 20.5839ZM95.967 63.688C98.527 63.688 100.799 63.1119 102.783 61.9599C104.767 60.7439 106.175 58.9839 107.007 56.6799V51.3999H96.735C90.591 51.3999 87.519 53.4159 87.519 57.4479C87.519 59.3679 88.255 60.9039 89.727 62.0559C91.263 63.1439 93.343 63.688 95.967 63.688Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M168.849 70.5039C167.377 71.5919 165.553 72.4239 163.377 72.9999C161.265 73.5119 159.025 73.7679 156.657 73.7679C150.513 73.7679 145.745 72.1999 142.353 69.0639C139.025 65.9279 137.361 61.3199 137.361 55.2399V34.0239H129.393V22.5039H137.361V9.92795H152.337V22.5039H165.201V34.0239H152.337V55.0479C152.337 57.2239 152.881 58.9199 153.969 60.1359C155.121 61.2879 156.721 61.8639 158.769 61.8639C161.137 61.8639 163.153 61.2239 164.817 59.9439L168.849 70.5039Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M194.632 73.7679C190.344 73.7679 186.152 73.2559 182.056 72.2319C177.96 71.1439 174.696 69.7999 172.264 68.1999L177.256 57.4479C179.56 58.9199 182.344 60.1359 185.608 61.0959C188.872 61.9919 192.072 62.4399 195.208 62.4399C201.544 62.4399 204.712 60.8719 204.712 57.7359C204.712 56.2639 203.848 55.2079 202.12 54.5679C200.392 53.9279 197.736 53.3839 194.152 52.9359C189.928 52.2959 186.44 51.5599 183.688 50.7279C180.936 49.8959 178.536 48.4239 176.488 46.3119C174.504 44.1999 173.512 41.1919 173.512 37.2879C173.512 34.0239 174.44 31.1439 176.296 28.6479C178.216 26.0879 180.968 24.1039 184.552 22.6959C188.2 21.2879 192.488 20.5839 197.416 20.5839C201.064 20.5839 204.68 20.9999 208.264 21.8319C211.912 22.5999 214.92 23.6879 217.288 25.0959L212.296 35.7519C207.752 33.1919 202.792 31.9119 197.416 31.9119C194.216 31.9119 191.816 32.3599 190.216 33.2559C188.616 34.1519 187.816 35.3039 187.816 36.7119C187.816 38.3119 188.68 39.4319 190.408 40.0719C192.136 40.7119 194.888 41.32 198.664 41.896C202.888 42.5999 206.344 43.3679 209.032 44.1999C211.72 44.9679 214.056 46.4079 216.04 48.5199C218.024 50.6319 219.016 53.5759 219.016 57.3519C219.016 60.5519 218.056 63.3999 216.136 65.8959C214.216 68.3919 211.4 70.3439 207.688 71.7519C204.04 73.0959 199.688 73.7679 194.632 73.7679Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M249.088 20.5839C257.088 20.5839 263.232 22.5039 267.52 26.3439C271.808 30.1199 273.952 35.8479 273.952 43.5279V72.9999H259.936V66.5679C257.12 71.3679 251.872 73.7679 244.192 73.7679C240.224 73.7679 236.768 73.0959 233.824 71.7519C230.944 70.4079 228.736 68.5519 227.2 66.1839C225.664 63.8159 224.896 61.1279 224.896 58.1199C224.896 53.3199 226.688 49.5439 230.272 46.7919C233.92 44.0399 239.52 42.6639 247.072 42.6639H258.976C258.976 39.3999 257.984 36.9039 256 35.1759C254.016 33.3839 251.04 32.4879 247.072 32.4879C244.32 32.4879 241.6 32.9359 238.912 33.8319C236.288 34.6639 234.048 35.8159 232.192 37.2879L226.816 26.8239C229.632 24.8399 232.992 23.3039 236.896 22.2159C240.864 21.1279 244.928 20.5839 249.088 20.5839ZM247.936 63.688C250.496 63.688 252.768 63.1119 254.752 61.9599C256.736 60.7439 258.144 58.9839 258.976 56.6799V51.3999H248.704C242.56 51.3999 239.488 53.4159 239.488 57.4479C239.488 59.3679 240.224 60.9039 241.696 62.0559C243.232 63.1439 245.312 63.688 247.936 63.688Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M287.506 1.76794H302.482V72.9999H287.506V1.76794Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M393.968 38.0559C397.808 39.2719 400.816 41.3199 402.992 44.1999C405.168 47.0159 406.256 50.5039 406.256 54.6639C406.256 60.5519 403.952 65.0959 399.344 68.2959C394.8 71.4319 388.144 72.9999 379.376 72.9999H344.624V5.79994H377.456C385.648 5.79994 391.92 7.36794 396.272 10.5039C400.688 13.6399 402.896 17.8959 402.896 23.2719C402.896 26.5359 402.096 29.4479 400.496 32.0079C398.96 34.5679 396.784 36.5839 393.968 38.0559ZM360.08 17.5119V33.3519H375.536C379.376 33.3519 382.288 32.6799 384.272 31.3359C386.256 29.9919 387.248 28.0079 387.248 25.3839C387.248 22.7599 386.256 20.8079 384.272 19.5279C382.288 18.1839 379.376 17.5119 375.536 17.5119H360.08ZM378.224 61.2879C382.32 61.2879 385.392 60.6159 387.44 59.2719C389.552 57.9279 390.608 55.8479 390.608 53.0319C390.608 47.4639 386.48 44.6799 378.224 44.6799H360.08V61.2879H378.224Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M448.367 20.5839C454.767 20.5839 459.919 22.5039 463.823 26.3439C467.791 30.1839 469.775 35.8799 469.775 43.4319V72.9999H454.798V45.7359C454.798 41.6399 453.903 38.5999 452.111 36.6159C450.319 34.5679 447.727 33.5439 444.335 33.5439C440.559 33.5439 437.55 34.7279 435.31 37.0959C433.07 39.3999 431.951 42.8559 431.951 47.4639V72.9999H416.974V1.76794H431.951V26.7279C433.934 24.7439 436.335 23.2399 439.151 22.2159C441.967 21.1279 445.039 20.5839 448.367 20.5839Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M504.181 20.5839C512.181 20.5839 518.326 22.5039 522.614 26.3439C526.902 30.1199 529.046 35.8479 529.046 43.5279V72.9999H515.029V66.5679C512.213 71.3679 506.965 73.7679 499.285 73.7679C495.317 73.7679 491.862 73.0959 488.918 71.7519C486.038 70.4079 483.829 68.5519 482.293 66.1839C480.757 63.8159 479.99 61.1279 479.99 58.1199C479.99 53.3199 481.782 49.5439 485.366 46.7919C489.014 44.0399 494.613 42.6639 502.165 42.6639H514.069C514.069 39.3999 513.078 36.9039 511.094 35.1759C509.11 33.3839 506.133 32.4879 502.165 32.4879C499.413 32.4879 496.693 32.9359 494.005 33.8319C491.381 34.6639 489.141 35.8159 487.285 37.2879L481.91 26.8239C484.726 24.8399 488.086 23.3039 491.99 22.2159C495.958 21.1279 500.021 20.5839 504.181 20.5839ZM503.03 63.688C505.59 63.688 507.861 63.1119 509.845 61.9599C511.829 60.7439 513.237 58.9839 514.069 56.6799V51.3999H503.798C497.654 51.3999 494.582 53.4159 494.582 57.4479C494.582 59.3679 495.317 60.9039 496.789 62.0559C498.325 63.1439 500.406 63.688 503.03 63.688Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M592.926 21.3519L571.134 72.9999H555.678L533.982 21.3519H549.438L563.742 56.4879L578.526 21.3519H592.926Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M615.663 73.7679C611.375 73.7679 607.183 73.2559 603.087 72.2319C598.991 71.1439 595.727 69.7999 593.295 68.1999L598.287 57.4479C600.591 58.9199 603.375 60.1359 606.639 61.0959C609.903 61.9919 613.103 62.4399 616.239 62.4399C622.575 62.4399 625.743 60.8719 625.743 57.7359C625.743 56.2639 624.879 55.2079 623.151 54.5679C621.423 53.9279 618.767 53.3839 615.183 52.9359C610.959 52.2959 607.471 51.5599 604.719 50.7279C601.967 49.8959 599.567 48.4239 597.519 46.3119C595.535 44.1999 594.543 41.1919 594.543 37.2879C594.543 34.0239 595.471 31.1439 597.327 28.6479C599.247 26.0879 601.999 24.1039 605.583 22.6959C609.231 21.2879 613.519 20.5839 618.447 20.5839C622.095 20.5839 625.711 20.9999 629.295 21.8319C632.943 22.5999 635.951 23.6879 638.319 25.0959L633.327 35.7519C628.783 33.1919 623.823 31.9119 618.447 31.9119C615.247 31.9119 612.847 32.3599 611.247 33.2559C609.647 34.1519 608.847 35.3039 608.847 36.7119C608.847 38.3119 609.711 39.4319 611.439 40.0719C613.167 40.7119 615.919 41.32 619.695 41.896C623.919 42.5999 627.375 43.3679 630.063 44.1999C632.751 44.9679 635.087 46.4079 637.071 48.5199C639.055 50.6319 640.047 53.5759 640.047 57.3519C640.047 60.5519 639.087 63.3999 637.167 65.8959C635.247 68.3919 632.431 70.3439 628.719 71.7519C625.071 73.0959 620.719 73.7679 615.663 73.7679Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M670.119 20.5839C678.119 20.5839 684.263 22.5039 688.551 26.3439C692.839 30.1199 694.983 35.8479 694.983 43.5279V72.9999H680.967V66.5679C678.151 71.3679 672.903 73.7679 665.223 73.7679C661.255 73.7679 657.799 73.0959 654.855 71.7519C651.975 70.4079 649.767 68.5519 648.231 66.1839C646.695 63.8159 645.927 61.1279 645.927 58.1199C645.927 53.3199 647.719 49.5439 651.303 46.7919C654.951 44.0399 660.551 42.6639 668.103 42.6639H680.007C680.007 39.3999 679.015 36.9039 677.031 35.1759C675.047 33.3839 672.071 32.4879 668.103 32.4879C665.351 32.4879 662.631 32.9359 659.943 33.8319C657.319 34.6639 655.079 35.8159 653.223 37.2879L647.847 26.8239C650.663 24.8399 654.023 23.3039 657.927 22.2159C661.895 21.1279 665.959 20.5839 670.119 20.5839ZM668.967 63.688C671.527 63.688 673.799 63.1119 675.783 61.9599C677.767 60.7439 679.175 58.9839 680.007 56.6799V51.3999H669.735C663.591 51.3999 660.519 53.4159 660.519 57.4479C660.519 59.3679 661.255 60.9039 662.727 62.0559C664.263 63.1439 666.343 63.688 668.967 63.688Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
            <path
              d="M722.841 28.1679C724.633 25.6719 727.033 23.7839 730.041 22.5039C733.113 21.2239 736.633 20.5839 740.601 20.5839V34.4079C738.937 34.2799 737.817 34.216 737.241 34.216C732.953 34.216 729.593 35.4319 727.161 37.8639C724.729 40.2319 723.513 43.8159 723.513 48.6159V72.9999H708.537V21.3519H722.841V28.1679Z"
              stroke={Colors.oxfordBlue}
              strokeWidth="3"
            />
          </svg>
          <div className={classes.bannerTitle}>{`& I'm a`}</div>
          <div className={classes.titleSvg}>
            <svg
              viewBox="0 0 941 98"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.myTitle}
            >
              <path
                d="M17.52 22.28V40.04H48.624V52.52H17.52V77H1.96802V9.8H52.752V22.28H17.52Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M75.591 32.168C77.383 29.672 79.783 27.784 82.791 26.504C85.863 25.224 89.383 24.584 93.351 24.584V38.408C91.687 38.28 90.567 38.216 89.991 38.216C85.703 38.216 82.343 39.432 79.911 41.864C77.479 44.232 76.263 47.816 76.263 52.616V77H61.287V25.352H75.591V32.168Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M126.363 77.768C120.923 77.768 116.027 76.648 111.675 74.408C107.387 72.104 104.027 68.936 101.595 64.904C99.163 60.872 97.947 56.296 97.947 51.176C97.947 46.056 99.163 41.48 101.595 37.448C104.027 33.416 107.387 30.28 111.675 28.04C116.027 25.736 120.923 24.584 126.363 24.584C131.803 24.584 136.667 25.736 140.955 28.04C145.243 30.28 148.603 33.416 151.035 37.448C153.467 41.48 154.683 46.056 154.683 51.176C154.683 56.296 153.467 60.872 151.035 64.904C148.603 68.936 145.243 72.104 140.955 74.408C136.667 76.648 131.803 77.768 126.363 77.768ZM126.363 65.48C130.203 65.48 133.339 64.2 135.771 61.64C138.267 59.016 139.515 55.528 139.515 51.176C139.515 46.824 138.267 43.368 135.771 40.808C133.339 38.184 130.203 36.872 126.363 36.872C122.523 36.872 119.355 38.184 116.859 40.808C114.363 43.368 113.115 46.824 113.115 51.176C113.115 55.528 114.363 59.016 116.859 61.64C119.355 64.2 122.523 65.48 126.363 65.48Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M196.085 24.584C202.485 24.584 207.637 26.504 211.541 30.344C215.509 34.184 217.493 39.88 217.493 47.432V77H202.517V49.736C202.517 45.64 201.621 42.6 199.829 40.616C198.037 38.568 195.445 37.544 192.053 37.544C188.277 37.544 185.269 38.728 183.029 41.096C180.789 43.4 179.669 46.856 179.669 51.464V77H164.693V25.352H178.997V31.4C180.981 29.224 183.445 27.56 186.389 26.408C189.333 25.192 192.565 24.584 196.085 24.584Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M264.38 74.504C262.908 75.592 261.084 76.424 258.908 77C256.796 77.512 254.556 77.768 252.188 77.768C246.044 77.768 241.276 76.2 237.884 73.064C234.556 69.928 232.892 65.32 232.892 59.24V38.024H224.924V26.504H232.892V13.928H247.868V26.504H260.732V38.024H247.868V59.048C247.868 61.224 248.412 62.92 249.5 64.136C250.652 65.288 252.252 65.864 254.3 65.864C256.668 65.864 258.684 65.224 260.348 63.944L264.38 74.504Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M321.785 51.368C321.785 51.56 321.689 52.904 321.497 55.4H282.425C283.129 58.6 284.793 61.128 287.417 62.984C290.041 64.84 293.305 65.768 297.209 65.768C299.897 65.768 302.265 65.384 304.313 64.616C306.425 63.784 308.377 62.504 310.169 60.776L318.137 69.416C313.273 74.984 306.169 77.768 296.825 77.768C291.001 77.768 285.849 76.648 281.369 74.408C276.889 72.104 273.433 68.936 271.001 64.904C268.569 60.872 267.353 56.296 267.353 51.176C267.353 46.12 268.537 41.576 270.905 37.544C273.337 33.448 276.633 30.28 280.793 28.04C285.017 25.736 289.721 24.584 294.905 24.584C299.961 24.584 304.537 25.672 308.633 27.848C312.729 30.024 315.929 33.16 318.233 37.256C320.601 41.288 321.785 45.992 321.785 51.368ZM295.001 35.912C291.609 35.912 288.761 36.872 286.457 38.792C284.153 40.712 282.745 43.336 282.233 46.664H307.673C307.161 43.4 305.753 40.808 303.449 38.888C301.145 36.904 298.329 35.912 295.001 35.912Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M363.148 24.584C369.548 24.584 374.7 26.504 378.604 30.344C382.572 34.184 384.556 39.88 384.556 47.432V77H369.58V49.736C369.58 45.64 368.684 42.6 366.892 40.616C365.1 38.568 362.508 37.544 359.116 37.544C355.34 37.544 352.332 38.728 350.092 41.096C347.852 43.4 346.732 46.856 346.732 51.464V77H331.756V25.352H346.06V31.4C348.044 29.224 350.508 27.56 353.452 26.408C356.396 25.192 359.628 24.584 363.148 24.584Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M451.301 5.768V77H436.997V71.048C433.285 75.528 427.909 77.768 420.869 77.768C416.005 77.768 411.589 76.68 407.621 74.504C403.717 72.328 400.645 69.224 398.405 65.192C396.165 61.16 395.045 56.488 395.045 51.176C395.045 45.864 396.165 41.192 398.405 37.16C400.645 33.128 403.717 30.024 407.621 27.848C411.589 25.672 416.005 24.584 420.869 24.584C427.461 24.584 432.613 26.664 436.325 30.824V5.768H451.301ZM423.461 65.48C427.237 65.48 430.373 64.2 432.869 61.64C435.365 59.016 436.613 55.528 436.613 51.176C436.613 46.824 435.365 43.368 432.869 40.808C430.373 38.184 427.237 36.872 423.461 36.872C419.621 36.872 416.453 38.184 413.957 40.808C411.461 43.368 410.213 46.824 410.213 51.176C410.213 55.528 411.461 59.016 413.957 61.64C416.453 64.2 419.621 65.48 423.461 65.48Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M545.438 64.52V77H493.406V9.8H544.19V22.28H508.862V36.872H540.062V48.968H508.862V64.52H545.438Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M588.148 24.584C594.548 24.584 599.7 26.504 603.604 30.344C607.572 34.184 609.556 39.88 609.556 47.432V77H594.58V49.736C594.58 45.64 593.684 42.6 591.892 40.616C590.1 38.568 587.508 37.544 584.116 37.544C580.34 37.544 577.332 38.728 575.092 41.096C572.852 43.4 571.732 46.856 571.732 51.464V77H556.756V25.352H571.06V31.4C573.044 29.224 575.508 27.56 578.452 26.408C581.396 25.192 584.628 24.584 588.148 24.584Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M676.973 25.352V68.36C676.973 77.832 674.509 84.872 669.581 89.48C664.653 94.088 657.453 96.392 647.981 96.392C642.989 96.392 638.253 95.784 633.773 94.568C629.293 93.352 625.581 91.592 622.637 89.288L628.589 78.536C630.765 80.328 633.517 81.736 636.845 82.76C640.173 83.848 643.501 84.392 646.829 84.392C652.013 84.392 655.821 83.208 658.253 80.84C660.749 78.536 661.997 75.016 661.997 70.28V68.072C658.093 72.36 652.653 74.504 645.677 74.504C640.941 74.504 636.589 73.48 632.621 71.432C628.717 69.32 625.613 66.376 623.309 62.6C621.005 58.824 619.853 54.472 619.853 49.544C619.853 44.616 621.005 40.264 623.309 36.488C625.613 32.712 628.717 29.8 632.621 27.752C636.589 25.64 640.941 24.584 645.677 24.584C653.165 24.584 658.861 27.048 662.765 31.976V25.352H676.973ZM648.653 62.216C652.621 62.216 655.853 61.064 658.349 58.76C660.909 56.392 662.189 53.32 662.189 49.544C662.189 45.768 660.909 42.728 658.349 40.424C655.853 38.056 652.621 36.872 648.653 36.872C644.685 36.872 641.421 38.056 638.861 40.424C636.301 42.728 635.021 45.768 635.021 49.544C635.021 53.32 636.301 56.392 638.861 58.76C641.421 61.064 644.685 62.216 648.653 62.216Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M690.912 25.352H705.888V77H690.912V25.352ZM698.4 18.152C695.648 18.152 693.408 17.352 691.68 15.752C689.952 14.152 689.088 12.168 689.088 9.8C689.088 7.432 689.952 5.448 691.68 3.848C693.408 2.248 695.648 1.448 698.4 1.448C701.152 1.448 703.392 2.216 705.12 3.752C706.848 5.288 707.712 7.208 707.712 9.512C707.712 12.008 706.848 14.088 705.12 15.752C703.392 17.352 701.152 18.152 698.4 18.152Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M751.179 24.584C757.579 24.584 762.731 26.504 766.635 30.344C770.603 34.184 772.587 39.88 772.587 47.432V77H757.611V49.736C757.611 45.64 756.715 42.6 754.923 40.616C753.131 38.568 750.539 37.544 747.147 37.544C743.371 37.544 740.363 38.728 738.123 41.096C735.883 43.4 734.763 46.856 734.763 51.464V77H719.787V25.352H734.091V31.4C736.075 29.224 738.539 27.56 741.483 26.408C744.427 25.192 747.659 24.584 751.179 24.584Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M837.317 51.368C837.317 51.56 837.221 52.904 837.029 55.4H797.956C798.66 58.6 800.324 61.128 802.948 62.984C805.572 64.84 808.836 65.768 812.74 65.768C815.428 65.768 817.796 65.384 819.844 64.616C821.956 63.784 823.909 62.504 825.701 60.776L833.669 69.416C828.805 74.984 821.701 77.768 812.357 77.768C806.533 77.768 801.381 76.648 796.901 74.408C792.421 72.104 788.964 68.936 786.532 64.904C784.1 60.872 782.885 56.296 782.885 51.176C782.885 46.12 784.069 41.576 786.437 37.544C788.869 33.448 792.165 30.28 796.325 28.04C800.549 25.736 805.253 24.584 810.437 24.584C815.493 24.584 820.068 25.672 824.164 27.848C828.26 30.024 831.461 33.16 833.765 37.256C836.133 41.288 837.317 45.992 837.317 51.368ZM810.533 35.912C807.141 35.912 804.293 36.872 801.989 38.792C799.685 40.712 798.277 43.336 797.765 46.664H823.205C822.693 43.4 821.285 40.808 818.981 38.888C816.677 36.904 813.861 35.912 810.533 35.912Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M897.879 51.368C897.879 51.56 897.783 52.904 897.591 55.4H858.519C859.223 58.6 860.887 61.128 863.511 62.984C866.135 64.84 869.399 65.768 873.303 65.768C875.991 65.768 878.359 65.384 880.407 64.616C882.519 63.784 884.471 62.504 886.263 60.776L894.231 69.416C889.367 74.984 882.263 77.768 872.919 77.768C867.095 77.768 861.943 76.648 857.463 74.408C852.983 72.104 849.527 68.936 847.095 64.904C844.663 60.872 843.447 56.296 843.447 51.176C843.447 46.12 844.631 41.576 846.999 37.544C849.431 33.448 852.727 30.28 856.887 28.04C861.111 25.736 865.815 24.584 870.999 24.584C876.055 24.584 880.631 25.672 884.727 27.848C888.823 30.024 892.023 33.16 894.327 37.256C896.695 41.288 897.879 45.992 897.879 51.368ZM871.095 35.912C867.703 35.912 864.855 36.872 862.551 38.792C860.247 40.712 858.839 43.336 858.327 46.664H883.767C883.255 43.4 881.847 40.808 879.543 38.888C877.239 36.904 874.423 35.912 871.095 35.912Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
              <path
                d="M922.154 32.168C923.946 29.672 926.346 27.784 929.354 26.504C932.426 25.224 935.946 24.584 939.914 24.584V38.408C938.25 38.28 937.13 38.216 936.554 38.216C932.266 38.216 928.906 39.432 926.474 41.864C924.042 44.232 922.826 47.816 922.826 52.616V77H907.849V25.352H922.154V32.168Z"
                stroke={Colors.oxfordBlue}
                strokeWidth="3"
              />
            </svg>
          </div>
        </div>
        <div className={`${classes.bannerContentRight} slide-in-bck-right`}>
          <lottie-player
            autoplay
            loop
            mode="normal"
            src="https://assets2.lottiefiles.com/packages/lf20_wswnpz7u.json"
          ></lottie-player>
        </div>
      </div>
      <div className={classes.bannerMarqueue}>
        <Marquee gradientWidth={20} gradientColor={[15, 22, 48]} speed={50}>
          <span>HTML</span>
          <span>CSS</span>
          <span>JavaScript</span>
          <span>Typescript</span>
          <span>ReactJs</span>
          <span>Redux</span>
          <span>NodeJs</span>
          <span>GraphQL</span>
          <span>Python</span>
          <span>MongoDB</span>

          <span>HTML</span>
          <span>CSS</span>
          <span>JavaScript</span>
          <span>Typescript</span>
          <span>ReactJs</span>
          <span>Redux</span>
          <span>NodeJs</span>
          <span>GraphQL</span>
          <span>Python</span>
          <span>MongoDB</span>

          <span>HTML</span>
          <span>CSS</span>
          <span>JavaScript</span>
          <span>Typescript</span>
          <span>ReactJs</span>
          <span>Redux</span>
          <span>NodeJs</span>
          <span>GraphQL</span>
          <span>Python</span>
          <span>MongoDB</span>
        </Marquee>
      </div>
    </div>
  );
};

export default Banner;
