import React from "react";
import skillsStyles from "./Skills.styles";
import { ReactComponent as HtmlIcon } from "../../assets/icons/html.svg";
import { ReactComponent as CssIcon } from "../../assets/icons/css.svg";
import { ReactComponent as JSIcon } from "../../assets/icons/javascript.svg";
import { ReactComponent as ReactIcon } from "../../assets/icons/react.svg";
import { ReactComponent as ReduxIcon } from "../../assets/icons/redux.svg";
import { ReactComponent as NodejsIcon } from "../../assets/icons/nodejs.svg";
import { ReactComponent as TSIcon } from "../../assets/icons/typescript.svg";
import { ReactComponent as PythonIcon } from "../../assets/icons/python.svg";
import { ReactComponent as MDBIcon } from "../../assets/icons/mongodb.svg";
import { ReactComponent as GQLIcon } from "../../assets/icons/graphql.svg";
import { ReactComponent as WebpackIcon } from "../../assets/icons/webpack.svg";
import { ReactComponent as JestIcon } from "../../assets/icons/jest.svg";

const Skills = () => {
  const classes = skillsStyles();

  const careerStartDate = new Date("2017-02-25");
  const today = new Date();

  const years = today.getFullYear() - careerStartDate.getFullYear();

  return (
    <section id="about" className={`${classes.skillsContainer} container`}>
      <div className="section-title">{`About me & my Career`}</div>
      <div className="section-description">
        {`As a Frontend Engineer with more than ${years} years of experience and a passion for UI UX, I possess a keen eye for detail and have contributed to several projects that have created intuitive user interfaces for web applications, utilizing my expertise in Javascript and ReactJs. I am continuously striving to learn more about UI UX design concepts so that I can take a more active role in the design process and ultimately achieve my goal of becoming a Product Designer.`}
      </div>
      <div className="section-sub-title">{`Skills I've used or practiced so far`}</div>
      <div className={classes.skillsList}>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <HtmlIcon />
          </div>
          <div className={classes.skillItemName}>HTML</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <CssIcon />
          </div>
          <div className={classes.skillItemName}>CSS</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <JSIcon />
          </div>
          <div className={classes.skillItemName}>JavaScript</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <ReactIcon />
          </div>
          <div className={classes.skillItemName}>ReactJs</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <ReduxIcon />
          </div>
          <div className={classes.skillItemName}>Redux</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <JestIcon />
          </div>
          <div className={classes.skillItemName}>Jest</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <WebpackIcon />
          </div>
          <div className={classes.skillItemName}>Webpack</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <TSIcon />
          </div>
          <div className={classes.skillItemName}>Typescript</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <GQLIcon />
          </div>
          <div className={classes.skillItemName}>GraphQL</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <NodejsIcon />
          </div>
          <div className={classes.skillItemName}>NodeJs</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <MDBIcon />
          </div>
          <div className={classes.skillItemName}>MongoDB</div>
        </div>
        <div className={classes.skillsListItem}>
          <div className={classes.skillItemIcon}>
            <PythonIcon />
          </div>
          <div className={classes.skillItemName}>Python</div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
